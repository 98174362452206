$black_color: #000000;
$orange_color: #db6e20;
$darkGrey_color: #636466;
$red_color: #d61212;

// These variables theme Bootstrap
$primary: $orange_color;
$orange: $orange_color;
$secondary: $darkGrey_color;

$fs_small: 11px;
$fs_medium: 14px;
$fs_medium_large: 18px;
$fs_medium_large_plus: 20px;
$fs_large: 22px;
$fs_xl: 29px;

@charset "UTF-8";
.vl-common-calendar .rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}
.vl-common-calendar button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}
.vl-common-calendar button[disabled].rbc-btn {
  cursor: not-allowed;
}
.vl-common-calendar button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.vl-common-calendar .rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.vl-common-calendar .rbc-calendar *,
.vl-common-calendar .rbc-calendar *:before,
.vl-common-calendar .rbc-calendar *:after {
  box-sizing: inherit;
}
.vl-common-calendar .rbc-abs-full,
.vl-common-calendar .rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.vl-common-calendar .rbc-ellipsis,
.vl-common-calendar .rbc-show-more,
.vl-common-calendar .rbc-row-segment .rbc-event-content,
.vl-common-calendar .rbc-event-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vl-common-calendar .rbc-rtl {
  direction: rtl;
}
.vl-common-calendar .rbc-off-range {
  color: #999999;
}
.vl-common-calendar .rbc-off-range-bg {
  background: #e6e6e6;
}
.vl-common-calendar .rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #ddd;
}
.vl-common-calendar .rbc-header + .vl-common-calendar .rbc-header {
  border-left: 1px solid #ddd;
}
.rbc-rtl .vl-common-calendar .rbc-header + .vl-common-calendar .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.vl-common-calendar .rbc-header > a,
.vl-common-calendar .rbc-header > a:active,
.vl-common-calendar .rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}
.vl-common-calendar .rbc-button-link {
  color: inherit;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: text;
}
.vl-common-calendar .rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}
.vl-common-calendar .rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.vl-common-calendar .rbc-row-content-scrollable .rbc-row-content-scroll-container {
  height: 100%;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.vl-common-calendar .rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}
.vl-common-calendar .rbc-today {
  background-color: #eaf6ff;
}
.vl-common-calendar .rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}
.vl-common-calendar .rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}
.vl-common-calendar .rbc-toolbar button {
  color: #373a3c;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #ccc;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}
.vl-common-calendar .rbc-toolbar button:active,
.vl-common-calendar .rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.vl-common-calendar .rbc-toolbar button:active:hover,
.vl-common-calendar .rbc-toolbar button:active:focus,
.vl-common-calendar .rbc-toolbar button.rbc-active:hover,
.vl-common-calendar .rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.vl-common-calendar .rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.vl-common-calendar .rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.vl-common-calendar .rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}
.vl-common-calendar .rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.vl-common-calendar .rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .vl-common-calendar .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-rtl .vl-common-calendar .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.vl-common-calendar .rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.vl-common-calendar .rbc-btn-group button + button {
  margin-left: -1px;
}
.rbc-rtl .vl-common-calendar .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}
.vl-common-calendar .rbc-btn-group + .vl-common-calendar .rbc-btn-group,
.vl-common-calendar .rbc-btn-group + button {
  margin-left: 10px;
}
.vl-common-calendar .rbc-event,
.vl-common-calendar .rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #3174ad;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.rbc-slot-selecting .vl-common-calendar .rbc-event,
.rbc-slot-selecting .vl-common-calendar .rbc-day-slot .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}
.vl-common-calendar .rbc-event.rbc-selected,
.vl-common-calendar .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #265985;
}
.vl-common-calendar .rbc-event:focus,
.vl-common-calendar .rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #3b99fc;
}
.vl-common-calendar .rbc-event-label {
  font-size: 80%;
}
.vl-common-calendar .rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}
.vl-common-calendar .rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.vl-common-calendar .rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.vl-common-calendar .rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.vl-common-calendar .rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vl-common-calendar .rbc-row {
  display: flex;
  flex-direction: row;
}
.vl-common-calendar .rbc-row-segment {
  padding: 0 1px 1px 1px;
}
.vl-common-calendar .rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}
.vl-common-calendar .rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
  color: #3174ad;
}
.vl-common-calendar .rbc-show-more:hover,
.vl-common-calendar .rbc-show-more:focus {
  color: #265985;
}
.vl-common-calendar .rbc-month-view {
  position: relative;
  border: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%;
}
.vl-common-calendar .rbc-month-header {
  display: flex;
  flex-direction: row;
}
.vl-common-calendar .rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}
.vl-common-calendar .rbc-month-row + .vl-common-calendar .rbc-month-row {
  border-top: 1px solid #ddd;
}
.vl-common-calendar .rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
}
.vl-common-calendar .rbc-date-cell.rbc-now {
  font-weight: bold;
}
.vl-common-calendar .rbc-date-cell > a,
.vl-common-calendar .rbc-date-cell > a:active,
.vl-common-calendar .rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}
.vl-common-calendar .rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}
.vl-common-calendar .rbc-day-bg {
  flex: 1 0 0%;
}
.vl-common-calendar .rbc-day-bg + .vl-common-calendar .rbc-day-bg {
  border-left: 1px solid #ddd;
}
.rbc-rtl .vl-common-calendar .rbc-day-bg + .vl-common-calendar .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.vl-common-calendar .rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px;
}
.vl-common-calendar .rbc-overlay > * + * {
  margin-top: 1px;
}
.vl-common-calendar .rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
}
.vl-common-calendar .rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}
.vl-common-calendar .rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #ddd;
  border-spacing: 0;
  border-collapse: collapse;
}
.vl-common-calendar .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}
.vl-common-calendar .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}
.vl-common-calendar .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #ddd;
}
.rbc-rtl .vl-common-calendar .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.vl-common-calendar .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #ddd;
}
.vl-common-calendar .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.rbc-rtl .vl-common-calendar .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}
.vl-common-calendar .rbc-agenda-time-cell {
  text-transform: lowercase;
}
.vl-common-calendar .rbc-agenda-time-cell .rbc-continues-after:after {
  content: ' »';
}
.vl-common-calendar .rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '« ';
}
.vl-common-calendar .rbc-agenda-date-cell,
.vl-common-calendar .rbc-agenda-time-cell {
  white-space: nowrap;
}
.vl-common-calendar .rbc-agenda-event-cell {
  width: 100%;
}
.vl-common-calendar .rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.vl-common-calendar .rbc-time-column .rbc-timeslot-group {
  flex: 1;
}
.vl-common-calendar .rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}
.vl-common-calendar .rbc-time-gutter,
.vl-common-calendar .rbc-header-gutter {
  flex: none;
}
.vl-common-calendar .rbc-label {
  padding: 0 5px;
}
.vl-common-calendar .rbc-day-slot {
  position: relative;
}
.vl-common-calendar .rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}
.vl-common-calendar .rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}
.vl-common-calendar .rbc-day-slot .rbc-event,
.vl-common-calendar .rbc-day-slot .rbc-background-event {
  border: 1px solid #265985;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}
.vl-common-calendar .rbc-day-slot .rbc-background-event {
  opacity: 0.75;
}
.vl-common-calendar .rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}
.vl-common-calendar .rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}
.vl-common-calendar .rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}
.vl-common-calendar .rbc-time-view-resources .rbc-time-gutter,
.vl-common-calendar .rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #ddd;
  z-index: 10;
  margin-right: -1px;
}
.vl-common-calendar .rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}
.vl-common-calendar .rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px;
}
.vl-common-calendar .rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}
.vl-common-calendar .rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}
.vl-common-calendar .rbc-time-view-resources .rbc-header,
.vl-common-calendar .rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0 px;
}
.vl-common-calendar .rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}
.vl-common-calendar .rbc-time-slot {
  flex: 1 0 0;
}
.vl-common-calendar .rbc-time-slot.rbc-now {
  font-weight: bold;
}
.vl-common-calendar .rbc-day-header {
  text-align: center;
}
.vl-common-calendar .rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}
.vl-common-calendar .rbc-slot-selecting {
  cursor: move;
}
.vl-common-calendar .rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid #ddd;
  min-height: 0;
}
.vl-common-calendar .rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
  text-align: right;
}
.vl-common-calendar .rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}
.vl-common-calendar .rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #ddd;
}
.vl-common-calendar .rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}
.vl-common-calendar .rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}
.vl-common-calendar .rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}
.vl-common-calendar .rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}
.rbc-rtl .vl-common-calendar .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #ddd;
}
.vl-common-calendar .rbc-time-header > .rbc-row:first-child {
  border-bottom: 1px solid #ddd;
}
.vl-common-calendar .rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}
.vl-common-calendar .rbc-time-header-cell-single-day {
  display: none;
}
.vl-common-calendar .rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #ddd;
}
.rbc-rtl .vl-common-calendar .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.vl-common-calendar .rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
}
.vl-common-calendar .rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #ddd;
  overflow-y: auto;
  position: relative;
}
.vl-common-calendar .rbc-time-content > .rbc-time-gutter {
  flex: none;
}
.vl-common-calendar .rbc-time-content > * + * > * {
  border-left: 1px solid #ddd;
}
.rbc-rtl .vl-common-calendar .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}
.vl-common-calendar .rbc-time-content > .rbc-day-slot {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}
.vl-common-calendar .rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}
.vl-common-calendar .rbc-time-header-gutter,
.vl-common-calendar .rbc-time-gutter,
.vl-common-calendar .rbc-header-gutter,
.vl-common-calendar .rbc-time-view .rbc-allday-cell,
.vl-common-calendar .rbc-day-slot .rbc-event-label,
.vl-common-calendar .rbc-time-view-resources .rbc-time-header-cell-single-day,
.vl-common-calendar .rbc-time-header-cell-single-day {
  display: none !important;
}
.vl-common-calendar .rbc-time-content,
.vl-common-calendar .rbc-time-view,
.vl-common-calendar .rbc-events-container,
.vl-common-calendar .rbc-time-header-content,
.vl-common-calendar .rbc-header,
.vl-common-calendar .rbc-header + .rbc-header,
.vl-common-calendar .rbc-time-slot {
  border: none;
}
.vl-common-calendar .rbc-time-content > * + * > * {
  border-left: none;
}
.vl-common-calendar .rbc-time-content > *:not(:last-child) {
  border-right: 1px solid #e5e7eb;
}
.vl-common-calendar .rbc-day-slot .rbc-event-content {
  align-items: center;
  display: flex;
  flex: 1 1 0;
  font-size: 12px;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 1;
  min-height: 1em;
  width: 100%;
  word-wrap: break-word;
}
.vl-common-calendar button.rbc-btn {
  overflow: visible;
  text-transform: none;
  appearance: button;
  cursor: pointer;
}
.vl-common-calendar button[disabled].rbc-btn {
  cursor: not-allowed;
}
.vl-common-calendar button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.vl-common-calendar .rbc-day-slot .rbc-event,
.vl-common-calendar .rbc-day-slot .rbc-background-event,
.vl-common-calendar .rbc-event {
  box-sizing: border-box;
  box-shadow: none;
  padding: var(--event-padding, 2px 5px);
  background-color: var(--ant-primary-2);
  border: none;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
}
.vl-common-calendar .rbc-event .css-reset-button,
.vl-common-calendar .rbc-day-slot .rbc-background-event .css-reset-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  box-shadow: inherit;
  text-shadow: inherit;
}
.vl-common-calendar .rbc-event .writing-mode,
.vl-common-calendar .rbc-day-slot .rbc-background-event .writing-mode {
  rotate: 90deg;
  padding: 0 4px;
}
@media (min-width: 540px) {
  .vl-common-calendar .rbc-day-slot .writing-mode {
    rotate: 0deg;
  }
}
.vl-common-calendar .rbc-event .css-reset-button:focus,
.vl-common-calendar .rbc-day-slot .rbc-background-event .css-reset-button:focus {
  outline: none !important;
}
.vl-common-calendar .rbc-slot-selecting .rbc-event,
.vl-common-calendar .rbc-slot-selecting .rbc-day-slot .rbc-background-event,
.vl-common-calendar .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}
.vl-common-calendar .rbc-event.rbc-selected,
.vl-common-calendar .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: var(--ant-primary-3);
  border: 2px solid var(--ant-primary-4);
  color: rgba(0, 0, 0, 0.95);
}
.vl-common-calendar .rbc-event:focus,
.vl-common-calendar .rbc-day-slot .rbc-background-event:focus,
.vl-common-calendar .rbc-event:focus-within,
.vl-common-calendar .rbc-day-slot .rbc-background-event:focus-within {
  outline: 5px auto var(--ant-primary-4, #3b99fc);
}
.vl-common-calendar .rbc-event:active,
.vl-common-calendar .rbc-day-slot .rbc-background-event:active {
  color: rgba(0, 0, 0, 0.5);
}
.vl-common-calendar .rbc-event:hover:not(.rbc-selected),
.vl-common-calendar .rbc-day-slot .rbc-background-event:hover:not(.rbc-selected),
.vl-common-calendar .rbc-event:focus:not(.rbc-selected),
.vl-common-calendar .rbc-day-slot .rbc-background-event:focus:not(.rbc-selected),
.vl-common-calendar .rbc-event:focus-within:not(.rbc-selected),
.vl-common-calendar .rbc-day-slot .rbc-background-event:focus-within:not(.rbc-selected) {
  background-color: var(--ant-primary-3);
}
.vl-common-calendar .rbc-current-time-indicator {
  height: 3px;
  background-color: var(--ant-primary-color);
}
.vl-common-calendar .rbc-button-link {
  border: none;
  padding: 0;
  background: none;
}
.vl-common-calendar .rbc-today {
  background-color: var(--ant-primary-1);
}


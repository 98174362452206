.vl-common-calendar {
  @import '../../../node_modules/react-big-calendar/lib/sass/styles.scss';

  .rbc-time-header-gutter,
  .rbc-time-gutter,
  .rbc-header-gutter,
  .rbc-time-view .rbc-allday-cell,
  .rbc-day-slot .rbc-event-label,
  .rbc-time-view-resources .rbc-time-header-cell-single-day,
  .rbc-time-header-cell-single-day {
    display: none !important;
  }

  .rbc-time-content,
  .rbc-time-view,
  .rbc-events-container,
  .rbc-time-header-content,
  .rbc-header,
  .rbc-header + .rbc-header,
  .rbc-time-slot {
    border: none;
  }

  .rbc-time-content > * + * > * {
    border-left: none;
  }

  .rbc-time-content > *:not(:last-child) {
    border-right: 1px solid #e5e7eb;
  }

  .rbc-day-slot .rbc-event-content {
    align-items: center;
    display: flex;
    flex: 1 1 0;
    font-size: 12px;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    letter-spacing: 1px;
    line-height: 1;
    min-height: 1em;
    width: 100%;
    word-wrap: break-word;
  }

  button.rbc-btn {
    overflow: visible;
    text-transform: none;
    appearance: button;
    cursor: pointer;
  }

  button[disabled].rbc-btn {
    cursor: not-allowed;
  }

  button.rbc-input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  .rbc-day-slot .rbc-event,
  .rbc-event {
    box-sizing: border-box;
    box-shadow: none;
    padding: var(--event-padding, 2px 5px);
    background-color: var(--ant-primary-2);
    border: none;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.75);
    cursor: pointer;
  }

  .rbc-event .css-reset-button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    box-shadow: inherit;
    text-shadow: inherit;
  }

  .rbc-event .writing-mode {
    rotate: 90deg;
    padding: 0 4px;
  }

  @media (min-width: 540px) {
    .rbc-day-slot .writing-mode {
      rotate: 0deg;
    }
  }

  .rbc-event .css-reset-button:focus {
    outline: none !important;
  }

  .rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none;
  }

  .rbc-event.rbc-selected {
    background-color: var(--ant-primary-3);
    border: 2px solid var(--ant-primary-4);
    color: rgba(0, 0, 0, 0.95);
  }

  .rbc-event:focus,
  .rbc-event:focus-within {
    outline: 5px auto var(--ant-primary-4, #3b99fc);
  }

  .rbc-event:active {
    color: rgba(0, 0, 0, 0.5);
  }

  .rbc-event:hover:not(.rbc-selected),
  .rbc-event:focus:not(.rbc-selected),
  .rbc-event:focus-within:not(.rbc-selected) {
    background-color: var(--ant-primary-3);
  }

  .rbc-current-time-indicator {
    height: 3px;
    background-color: var(--ant-primary-color);
  }

  .rbc-button-link {
    border: none;
    padding: 0;
    background: none;
  }

  .rbc-today {
    background-color: var(--ant-primary-1);
  }
}
